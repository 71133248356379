import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import * as Tooltip from '@radix-ui/react-tooltip'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import ReactPlayer from 'react-player/file'
import ReactSlider from 'react-slider'
import RecordingPlaybackModalMediaPlaybackControls from './RecordingPlaybackModalMediaPlaybackControls'
import {getSpeakerForTranscript} from '../../utils/meetings/getSpeakerForTranscript'

const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000

const HEIGHT_SPEAKER_TIMELINE = 23
const MAX_SPEAKER_TIMELINES = 5

class RecordingPlaybackModalMedia extends React.Component{  
	 constructor(props){
    super(props)
    this.ref=this.ref.bind(this)
    this.handleProgress=this.handleProgress.bind(this) 
    this.handleSeekChange=this.handleSeekChange.bind(this)
    this.handleSeekMouseUp=this.handleSeekMouseUp.bind(this)
    this.handleDoubleClick=this.handleDoubleClick.bind(this)
    //this.handleSkip=this.handleSkip.bind(this)
    this.handleSeekMouseDown=this.handleSeekMouseDown.bind(this)
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveShort=this.hideMouseMoveShort.bind(this)    
    this.hideMouseMoveLong=this.hideMouseMoveLong.bind(this)    
    this.handleVolumeChange=this.handleVolumeChange.bind(this)
    this.handleVolumeMouseUp=this.handleVolumeMouseUp.bind(this)
    this.handleVolumeMouseDown=this.handleVolumeMouseDown.bind(this)  
   	this.handlePlayerReady=this.handlePlayerReady.bind(this)  
    this.changePlaybackRate=this.changePlaybackRate.bind(this)
    this.handleOnSeek=this.handleOnSeek.bind(this)
    this.hideMouseMoveShort=debounce(this.hideMouseMoveShort,MOUSE_MOVE_SHORT_DEBOUNCE_TIME)
    this.hideMouseMoveLong=debounce(this.hideMouseMoveLong,MOUSE_MOVE_LONG_DEBOUNCE_TIME)
    this.state={
      url: null,	    
	    // playing: false,	    
	    played: 0,
	    loaded: 0,
	    duration: 0,	          
      seeking: false,
      mouseMovedShort:false,
      mouseMovedLong:false,
      captionsActive: false,           
      volume: 1,
      adjustingVolume: false,
      muted:false,
      playerLoaded:false,
      hasPlayed:false,
      playbackRate: 1,
    }
  }

  componentDidMount(){
  	const storyboardImage = new Image();
  	storyboardImage.src = `https://image.mux.com/${this.props.playbackId}/storyboard.png`
  }

  handlePlayerReady(){
    if(!this.props.hasPlayed){
      this.props.handlePlayerReady()
    }
  }

  // handlePlayPause(){
  //   this.setState({playing:!this.state.playing})
  // }

  // handlePlay(){
  //   this.setState({playing:true,hasPlayed:true})
  // }

  // handlePause(){
  //   this.setState({playing:false})
  // }

  handleSeekMouseDown(){
    this.setState({seeking:true,muted:true})
  }  
 
  handleSeekChange(e){
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e})
  }

  handleSeekMouseUp(e){
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e,muted:false})
  }

  handleProgress(state){
  	this.props.handleVideoProgress(state.playedSeconds)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }
  handleOnSeek(seconds){
    this.props.handleVideoProgress(seconds)
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
    this.player.seekTo(0)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
    this.props.handleDuration(duration)
  }

  changePlaybackRate = (playbackRate) => {
    this.setState({ playbackRate })
  }

  ref(player){
    this.player = player
    this.props.setPlayerRef(player)
  }

  handleMouseMove(){    
    this.setState({mouseMovedShort:true})
    this.setState({mouseMovedLong:true})
    this.hideMouseMoveShort()
    this.hideMouseMoveLong()
  }

  hideMouseMoveShort(){
    this.setState({mouseMovedShort:false})
  }

  hideMouseMoveLong(){
    this.setState({mouseMovedLong:false})
  }

  handleVolumeMouseUp = e => {
    this.setState({ adjustingVolume: false })
    this.setState({ volume: parseFloat(e) })
  }

  handleVolumeMouseDown = e => {
    this.setState({ adjustingVolume: true })   
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e) })    
  }

  handleDoubleClick(e){
  	e.stopPropagation()
  }

	render(){ 
		const { mediaContainerWidth, mediaContainerHeight, playing,highlights } = this.props
    const { url, controls, played, loaded, duration, volume, zoomed, mouseMovedShort, mouseMovedLong, hasPlayed, adjustingVolume} = this.state		
    const progressSeconds = duration * played
	   
    const mediaType = this.props.recording.is_audio?'audio':'video'
    const muxPlaybackID = this.props.playbackId
    // const mediaType = 'audio'
    // const muxPlaybackID = 'qMo336QidtK00u007f02Rk4DARdNb8w5jjSBC3bY93HyhA' // audio example

    const playersControlsFixedHeight = 184 // everything excluding speaker timelines which is dynamic

    let numberOfSpeakers = 0
    if(this.props.recording.speakers){
      numberOfSpeakers = this.props.recording.speakers.length
    }
    
    let speakerTimelinesContainerHeight = numberOfSpeakers * HEIGHT_SPEAKER_TIMELINE
    
    if(numberOfSpeakers > MAX_SPEAKER_TIMELINES){
      speakerTimelinesContainerHeight = 0 // don't show speaker timeline over 5
    }

    const playersControlsHeight = playersControlsFixedHeight + speakerTimelinesContainerHeight
    

    const availableWidth = mediaContainerWidth
    const availableHeight = mediaContainerHeight - playersControlsHeight

    const availableAspect = availableWidth / availableHeight

    //
        
    let deliveryUrl
    let fitMediaWidth
    let fitMediaHeight
    let videoPosterImageUrl

    if(mediaType === 'video'){
      deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}/high.mp4`
      const videoAspect = this.props.videoAspect
      if(availableAspect >= videoAspect ){      
        fitMediaHeight = availableHeight
        fitMediaWidth = fitMediaHeight * videoAspect        
      }
      if(availableAspect < videoAspect){      
        fitMediaWidth = availableWidth
        fitMediaHeight = fitMediaWidth / videoAspect
      }
      videoPosterImageUrl = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=${Math.round(fitMediaWidth * 2)}&fit_mode=preserve`
    }else if(mediaType === 'audio'){
      deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}.m3u8`
      fitMediaWidth = availableWidth
      fitMediaHeight = availableHeight
    }

    let speakerName

    let speakerAvatar  
    let contactId

    if(mediaType=='audio'){
      const {activeSpeaker}=this.props 
      if(activeSpeaker || activeSpeaker==0){
        speakerName=`Speaker ${activeSpeaker}`
        const speaker=getSpeakerForTranscript(this.props.recording.recording_id,activeSpeaker)
        if(speaker){
          if(speaker.user_id){
            speakerName = speaker.display_name
            speakerAvatar=speaker.avatar

          }else{
            speakerName = speaker.name || speaker.email
            contactId = speaker.contact_id
          }
        }
      }
    }
    //    


    let speakers=[]
    if(this.props.recording){
      if(this.props.recording.onboarding_recording){
        speakers=[{contact_id:'ryan',transcript_speaker:0},{contact_id:'stewart',transcript_speaker:1}]
      }
      else if(!this.props.recording.onboarding_recording && this.props.recording.speakers){
        speakers=this.props.recording.speakers
      }
    }
 
    
		return (
			<div className='recordingPlaybackModal-media'>
        <div className='recordingPlaybackModal-media-playerContainer'>          
					<div style={{width: `${fitMediaWidth}px`, height: `${fitMediaHeight}px`}} onClick={this.props.handlePlayPause} className='recordingPlaybackModal-media-player'>											
            {mediaType === 'video' && videoPosterImageUrl && !hasPlayed && played === 0 && !playing && 
              <div className='recordingPlaybackModal-media-player-videoPoster'>
                <img src={videoPosterImageUrl} className='recordingPlaybackModal-media-player-videoPoster-img' />
              </div>
            }
            {mediaType === 'audio' && 
              <div className='recordingPlaybackModal-media-player-audioContainer'>
                {speakerName &&
                  <div className='recordingPlaybackModal-media-player-audioContainer-speaker'>                  
                    {(speakerAvatar || contactId) &&
                    <div className='recordingPlaybackModal-media-player-audioContainer-speaker-avatarContainer'>                    
                      <AvatarImage 
                        className='recordingPlaybackModal-media-player-audioContainer-speaker-avatar'
                        avatarUrl={speakerAvatar}
                        contactId={contactId}           
                        sideLength={64}
                      />                    
                    </div>
                    }
                    <div className='recordingPlaybackModal-media-player-audioContainer-speaker-name'>
                      {speakerName}
                    </div>
                  </div>
                }
                
                {/*
                <div className='recordingPlaybackModal-media-player-audioContainer-iconContainer'>
                  <Icon name='playbackAudio' />
                </div>
                */}
              </div>
            }
						<ReactPlayer   	
              ref={this.ref}		  		  			    
	            className='recordingPlaybackModal-media-player-video'
	            width='100%'
	            height='100%'
	            url={deliveryUrl}                
	            progressInterval={50} //TODO maybe make this dynamic depending on length of video
	            //pip={pip}
              playbackRate={this.state.playbackRate}
	            playing={playing}
	            muted={this.state.muted}
	            volume={volume}		                 
	            onPlay={this.props.handlePlay}
	            //onEnablePIP={this.handleEnablePIP}
	            //onDisablePIP={this.handleDisablePIP}
	            onPause={this.props.handlePause}
	            onEnded={this.handleEnded}
	            onProgress={this.handleProgress}
	            onDuration={this.handleDuration}
	            onEnded={this.handleEnded}
	            onReady={this.handlePlayerReady}
              onSeek={this.handleOnSeek}

	    			/>              
					</div>	
        </div>
        <div style={{height: `${playersControlsHeight}px`, width: `${fitMediaWidth - 10}px`}} className='recordingPlaybackModal-media-controlsContainer'>
          <RecordingPlaybackModalMediaPlaybackControls 
            mediaType={mediaType}
            paragraphs={this.props.paragraphs}
            skipToSpeakerChunk={this.props.skipToSpeakerChunk}
            playing={playing}
            handlePlayPause={this.props.handlePlayPause}
            skipBack15={()=>{this.props.handleSkip('backward', 15000)}}
            skipForward15={()=>{this.props.handleSkip('forward', 15000)}}
            progressSeconds={progressSeconds}
            duration={duration}
            played={played}

            muxPlaybackID={muxPlaybackID}
            handleSeekChange={this.handleSeekChange}
            handleSeekMouseDown={this.handleSeekMouseDown}
            handleSeekMouseUp={this.handleSeekMouseUp}
            speakers={speakers}
            speakerTimelinesContainerHeight={speakerTimelinesContainerHeight}
            speakerTimelineHeight={HEIGHT_SPEAKER_TIMELINE}            
            playbackRate={this.state.playbackRate}
            changePlaybackRate={this.changePlaybackRate}

            highlightMode={this.props.highlightMode}
            startHighlightMode={this.props.startHighlightMode}
            endHighlightMode={this.props.endHighlightMode}
            toggleHighlightMode={this.props.toggleHighlightMode}
            highlights={this.props.highlights}
            skipToHighlight={this.props.skipToHighlight}

            showNewHighlightPanel={this.props.showNewHighlightPanel}
            cmdKeyIsDown={this.props.cmdKeyIsDown}
            cancelCreateHighlight={this.props.cancelCreateHighlight}

          />
        </div>
			</div>
		)
	}
}

export default RecordingPlaybackModalMedia
{/*}  
            <div className='playgroundtestvideoButtons'>
              <button>
                prev speaker
              </button>
              
              <button onClick={()=>{this.handleSkip('backward', 15000)}}>
                back 15 sec
              </button>              
              
              <button onClick={this.handlePlayPause}>
                {playing ? 'pause' : 'play'}
              </button>
              
              <button onClick={()=>{this.handleSkip('forward', 15000)}}>
                forward 15 sec
              </button>
              
              <button>
                next speaker
              </button>
            </div>
            */}
            {/*}
            <div className='playgroundtestvideoButtonsSeekBar'>
              <div className='doc-video-inlineUI-controlBar-seekBarContainer'>
                <ReactSlider
                  className="doc-video-inlineUI-controlBar-seekBar"
                  thumbClassName="doc-video-inlineUI-controlBar-seekBar-thumb"
                  trackClassName="doc-video-inlineUI-controlBar-seekBar-track"                  
                  renderThumb={(props, state) => <div className='doc-video-inlineUI-controlBar-seekBar-thumb-thumb' {...props} />}

                  value={played}
                  min={0}
                  max={0.999999}
                  step={0.0001}
                  onAfterChange={this.handleSeekMouseUp}
                  onBeforeChange={this.handleSeekMouseDown}
                  onChange={this.handleSeekChange}
                />              
              </div>
            </div>
            </div>  
            */}

                  


