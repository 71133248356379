import store from '../../store'
import find from 'lodash/find'

export function getSpeakerForTranscript(recordingId,transcript_speaker) {	

	const state = store.getState()
	const recording= find(state.recordings, {recording_id:recordingId})
	
	let speaker 

	if(recording.onboarding_recording){ //hacky lets hardcode for now
		if(transcript_speaker==0){
			speaker={contact_id:'stewart',name:'Stewart Butterfield'}
		}else{
			speaker={contact_id:'ryan',name:'Ryan Smith'}
		}

	}
	else{
		const speakers=recording.speakers 
		const speakerObj=find(speakers,{transcript_speaker:transcript_speaker})
		if(speakerObj.user_id){
			speaker=find(state.orgMembers, {'user_id':speakerObj.user_id})
		}
		else if(speakerObj.contact_id){
			speaker=find(state.contacts, {'contact_id':speakerObj.contact_id})
		}

	}

	return speaker
}

